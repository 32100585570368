import { useEffect, useState } from 'react';

import './styles.scss';

import Logo from "./assets/logo";
import PrimaryText from "./assets/primary-text";
import SecondaryText from "./assets/secondary-text";
import Composition from "./assets/composition";
import ContentMobile from './assets/content-mobile';
import CompositionMobile from './assets/composition-mobile';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      const isMobileDevice = window.matchMedia('(max-width: 768px)').matches;
      setIsMobile(isMobileDevice);
    }
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    }
  }, []);

  const Header = () => {
    return (
      <div className="header">
        {
          <Logo width={isMobile ? "120px" : "180px"} />
        }
      </div>
    );
  }

  const Content = () => {
    return (
      <div className={isMobile ? "mobile-content" : "content"}>
        {
          isMobile ?
            <ContentMobile /> :
            <>
              <PrimaryText />
              <SecondaryText />
            </>
        }
      </div>
    );
  }

  const Image = () => {
    return (
      <div className="globe">
        {
          isMobile ?
            <CompositionMobile /> :
            <Composition />
        }
      </div>
    );
  }

  return (
    <div className="app">
      <Header />
      <Content />
      <Image />
    </div>
  );
}

export default App;